import React, { useState, useContext, useMemo } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { BlathersContext } from "@/contexts/Blathers"

import BlathersLayout from "@/components/BlathersLayout"
import InsectCard from "@/components/InsectCard"
import NoMatch from "@/components/NoMatch"
import { Tabs,MultiLineTabs, TextTab } from "@/components/Tabs"

import createNodeId from "@/utils/createNodeId"

const BlathersMuseumPage = () => {
  const queryData = useStaticQuery(graphql`
    query {
      allBlathersYaml {
        nodes {
          fossils {
            name {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            icon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  const {
    fossils
  } = queryData.allBlathersYaml.nodes[0]

  const formatFossils = fossils.map((fossil) => ({
    ...fossil,
    isDonated: false,
    id: createNodeId(fossil.name.en, `acnh`),
  }))

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <BlathersLayout>
      <MultiLineTabs value={value} onChange={handleChange}>
        <TextTab label="all" />
        <TextTab label="notDonated" />
        <TextTab label="donated" />
      </MultiLineTabs>
      <InsectCards
        tabs={{ value }}
        insects={formatFossils}
        type="fossil"
      />
    </BlathersLayout>
  )
}

export default BlathersMuseumPage

const InsectCards = ({ insects, type, tabs }) => {

  const { blathers } = useContext(BlathersContext)

  const currentInsects = useMemo(() => {
    const { value } = tabs
    const insectsHasMarked = insects.map((insect) => {
      const insecthasMarked = blathers.filter((id) => id === insect.id)
      if (insecthasMarked.length) {
        return {
          ...insect,
          isDonated: true,
        }
      } else {
        return {
          ...insect,
        }
      }
    })
    let insectsArr = [...insectsHasMarked]

    if (value === 1) {
      insectsArr = insectsHasMarked.filter((insect) => !insect.isDonated)
    } else if (value === 2) {
      insectsArr = insectsHasMarked.filter((insect) => insect.isDonated)
    }
    
    return insectsArr
  }, [blathers, insects, tabs])

  return (
    <StyledInsectCards>
      {currentInsects.length ? (
        <div className="insect_cards">
          {currentInsects.map((insect) => (
            <InsectCard insect={insect} type={type} key={insect.name.en} />
          ))}
        </div>
      ) : (
        <NoMatch />
      )}
    </StyledInsectCards>
  )
}

const StyledInsectCards = styled.div`
  .insect_cards {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px;
    @media (max-width: 450px) {
      padding: 10px 0;
    }
  }
`